// extracted by mini-css-extract-plugin
export const navbarLink = "Header-module--navbar-link--2v10E";
export const container = "Header-module--container--3aKh0";
export const menu_desktop = "Header-module--menu_desktop--YFOFd";
export const menu_item_link = "Header-module--menu_item_link--2z3LF";
export const beginnerIcon = "Header-module--beginner-icon--1tpET";
export const arrow_icon = "Header-module--arrow_icon--KKLko";
export const nav_border = "Header-module--nav_border--2Mvjk";
export const navbar_dropdown = "Header-module--navbar_dropdown--1NjBY";
export const navbar_item = "Header-module--navbar_item--3cC1G";
export const navbar_icon = "Header-module--navbar_icon--3JzO-";
export const accordion_nav = "Header-module--accordion_nav--9r187";
export const navbar_item_container = "Header-module--navbar_item_container--3K-fI";
export const navbar_item_inner = "Header-module--navbar_item_inner--lbcw6";
export const navbar_item_container_qa = "Header-module--navbar_item_container_qa--2ht3B";
export const navbar_simu_button = "Header-module--navbar_simu_button--xRspO";
export const navbar_button = "Header-module--navbar_button--1a6A_";
export const fadeIn = "Header-module--fadeIn--tOQvy";
export const campaigns_pr = "Header-module--campaigns_pr--El2a8";