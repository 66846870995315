// extracted by mini-css-extract-plugin
export const navbarLink = "HeaderMegaMenu-module--navbar-link--2zKNW";
export const container = "HeaderMegaMenu-module--container--2aSKv";
export const menu_desktop = "HeaderMegaMenu-module--menu_desktop--3moMn";
export const menu_item_link = "HeaderMegaMenu-module--menu_item_link--2yR-p";
export const beginnerIcon = "HeaderMegaMenu-module--beginner-icon--f-k8u";
export const arrow_icon = "HeaderMegaMenu-module--arrow_icon--3rtDk";
export const nav_border = "HeaderMegaMenu-module--nav_border--1AS78";
export const navbar_dropdown = "HeaderMegaMenu-module--navbar_dropdown--3KUjS";
export const navbar_item = "HeaderMegaMenu-module--navbar_item--2inNX";
export const navbar_icon = "HeaderMegaMenu-module--navbar_icon--1t5NJ";
export const navbar_item_container = "HeaderMegaMenu-module--navbar_item_container--3SRIH";
export const navbar_item_inner = "HeaderMegaMenu-module--navbar_item_inner--gVry8";
export const navbar_item_container_qa = "HeaderMegaMenu-module--navbar_item_container_qa--1nDeM";
export const navbar_simu_button = "HeaderMegaMenu-module--navbar_simu_button--2Cl_e";
export const accordion_nav = "HeaderMegaMenu-module--accordion_nav--3kLp7";
export const navbar_button = "HeaderMegaMenu-module--navbar_button--2wG8X";
export const fadeIn = "HeaderMegaMenu-module--fadeIn--SCMpQ";
export const campaigns_pr = "HeaderMegaMenu-module--campaigns_pr--3mgqD";
export const menu_mega_background = "HeaderMegaMenu-module--menu_mega_background--1u-ik";
export const isOpen = "HeaderMegaMenu-module--is-open--1rQHD";
export const menu_mega_container = "HeaderMegaMenu-module--menu_mega_container--3F1UI";
export const menu_mega_content_container = "HeaderMegaMenu-module--menu_mega_content_container--3v-PD";
export const menu_mega_content_inner = "HeaderMegaMenu-module--menu_mega_content_inner--1gh4r";
export const menu_mega_content_items = "HeaderMegaMenu-module--menu_mega_content_items--3Uy6s";
export const menu_mega_content_items_child = "HeaderMegaMenu-module--menu_mega_content_items_child--218eK";
export const menu_mega_content_link = "HeaderMegaMenu-module--menu_mega_content_link--2ox_V";
export const menu_mega_content_close = "HeaderMegaMenu-module--menu_mega_content_close--3rUN9";
export const menu_mega_hover_space = "HeaderMegaMenu-module--menu_mega_hover_space--3u8ty";
export const menu_item_link_hovered = "HeaderMegaMenu-module--menu_item_link_hovered--3SdRB";
export const isVisible = "HeaderMegaMenu-module--is-visible--3NVkN";